/*Please see HACKING.md for styling of Antd components.*/

.featureSwitch {
    /* antd sets the line-height of the label to the size of the container. This isn't ideal when the window isn't wide, because the
    line becomes larger than the header. By setting this value to 'normal', the label and text will wrap with a line height that
    allows it to stay within the header. */
    line-height: normal;
    text-align: right;
}

.featureSwitch label {
    margin-right: .5em;
}

.featureSwitch :global .ant-switch.ant-switch-checked {
    background-color: var(--loop54-primary-color);
}
