/*Please see HACKING.md for styling of Antd components.*/
@import "../Loop54.css";

.fullheight {
    min-height: 100vh;
}

.header {
    display: flex;
    align-items: center;
    background-color: #fff;
    height: 91px;
    padding: 0 25px;
    box-shadow: 0px -4px 40px rgb(0 0 0 / 10%);
}

.headerLogo a img{
    height: 40px;
}

.headerSiteSelectWrapper{
    display: flex;
    margin-left:auto;
    margin-right:25px;
}

.headerSiteSelectWrapper :global .ant-select {
    display: block;
}

.headerSiteSelectWrapper :global .ant-select .ant-select-selector{
    height: 41px;
    background-color: #F5F8FB;
}

.headerSiteSelectWrapper :global .ant-select .ant-select-selector .ant-select-selection-item{
    line-height: 41px;
}

.headerSiteSelectWrapper :global .ant-select .ant-select-selector .ant-select-selection-search-input{
    height: 41px;
}

.headerUserWrapper{
    display: flex;
    align-items: center;
    font-size: 1.1rem;
}
@media only screen and (max-width: 768px) {
    .headerUserWrapper{
        display:none;
    }
}

.headerUserWrapper span{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 250px;
}

.headerSignoutWrapper{
    margin-left:25px;
}

.headerDropdownWrapper {
    margin-left:25px;
}

.headerDropdownWrapper :global .ant-dropdown-trigger{
    display: block;
    line-height: 16px;
}
.headerDropdownWrapper :global .ant-dropdown-trigger .anticon-setting{
    font-size: 20px;
}

.headerDropdownWrapper :global .ant-dropdown-trigger .anticon-down{
    font-size: 13px;
    margin-left: 0.2em;
}

/*Avoid jagged edges in Chrome*/
:global img {
    outline: 1px solid transparent;
}

:global .ant-tag {
    display:inline;
}

:global .ant-tag:hover {
    opacity: initial;
}

:global .ant-page-header {
    background: none;
    line-height: 1;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
}

:global .ant-page-header-heading {
    align-items: center;
    padding: 0px 12px;
    margin-bottom:20px;
}

:global .ant-page-header-heading .ant-page-header-heading-title h1.ant-typography{
   font-size: 1.6rem;
}

:global .ant-page-header-title-view {
    width: 100%;
    border-bottom: 1px solid lightgray;
    padding-bottom: 12px;
}

:global .ant-page-header-title-view-title,
:global h1.ant-typography {
    line-height: 1;
    margin-bottom: 0;
}
:global .ant-page-header-title-view-title {
    float: left;
    margin: 12px 0 0 12px;
    padding: 0;
}
:global .ant-page-header-title-view-extra {
    margin: 12px 12px 0 12px;
    float: right;
}

:global .breadcrumb {
    display: flex;
    gap: 10px;
    color: var(--loop54-primary-color);
    font-weight: 400;
}

:global .breadcrumb .current {
    border-bottom: 2px solid var(--loop54-primary-color);
    padding-bottom: 6px;
}

/* Header and sider fixed pos */
:global .ant-layout-header{
    position: fixed;
    z-index: 1000;
    width: 100%;
}

:global .ant-layout-sider{ 
    /* Have the side nav to be as high as the current viewport minus the height of the top nav and the collapse toggle height*/
    height: calc(100vh - 141px);
    /* Push the sider nav 91px from top to clear it from the top nav*/
    margin-top: 91px;
    overflow: auto;
    position: fixed;
    left: 0;
}

/* Sider menu scrollbar styles */
:global .ant-layout-sider::-webkit-scrollbar {
    /* Sider menu scrollbar width */
    width: 5px;
}
:global .ant-layout-sider::-webkit-scrollbar-track {
    background: #f1f1f1;
}
:global .ant-layout-sider::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 8px;
}
:global .ant-layout-sider::-webkit-scrollbar-thumb:hover {
    background: #a8a8a8;
}

.siderCollapseBtn:global.ant-btn{
    /* Position the btn container in the bottom left corner below the sider menu. */
    position: fixed;
    bottom:0;
    left:0;
    /* Set the button container the same width as the sider menu */
    width: 280px;
    height: 50px;
    background-color: white;
    border-radius: 0;
    transition: all 0.2s;
}

.siderCollapseBtn.siderCollapseBtnCollapsed{
    /* This is the sider menus collapsed width, we want the same for the button container. */
    width: 80px;
}

.siderCollapseBtn :global .anticon{
    filter: drop-shadow(1px -1.5px 0px var(--loop54-cyan-color));
    position: relative;
    top:3px;
}

:global .ant-layout-content{ 
    /* Push the main content area 91px from the top (91px is the height of the top nav bar) */
    margin-top: 91px;
    /* Push the main content area 280px from the left (280px is the width of the sider nav) */
    margin-left: 280px;
    /* This is so that main content have some initial height. In this case we would like it to be
    the full height of the viewport minus the height of the top nav bar so that we don't have any Y scrollbar */
    min-height: calc(100vh - 91px);
    /* Add some transition to layout when it's transforming in width due to the collapsed sider nav */
    transition: margin-left ease-in-out 0.2s;
    padding: 25px;
}

/* When the sider nav is collapsed we want the main content to be pushed only 80px (width of collapsed sider nav) from the left */
:global .ant-layout-sider-collapsed + .ant-layout-content {
    margin-left: 80px;
}

/* Styling of ant-pagination */
:global .ant-pagination {
    color: var(--loop54-primary-color);
    font-size: 0.9em;
}

:global .ant-list-pagination:empty {
    display: none;
}

:global .ant-list-pagination {
    margin-top:0;
    padding:1em;

}

:global .ant-pagination.mini .ant-pagination-item {
    margin: 0 5px 0 0;
}
:global .ant-pagination.mini .ant-pagination-next {
    margin-left:-5px;
}

/* Ant tooltip styles */
:global .ant-tooltip {
    font-size: 0.9rem;
    font-weight: 300;
    max-width: 300px;
}

:global .ant-tooltip-inner {
    background-color: var(--loop54-tooltip-color);
    padding: 9px 15px;
    border-radius: 5px;
}

:global .ant-tooltip-arrow-content {
    width: 10px;
    height: 10px;
    background-color: var(--loop54-tooltip-color);
}

:global .ant-tooltip-arrow {
    width: 15px;
    height: 13px;
}

/* Ant tag styles */
:global .ant-tag{
    padding: 8px 20px;
    border-radius: 8px;
}

:global .ant-tag-default, :global .ant-tag-selected {
    font-size: 15px;
    font-weight: 400;

    /* Figma spec says 8px 20px, but that is way to large for the current design */
    padding: calc(8px * 0.6) calc(20px * 0.6);
    border-radius: 8px;

    border-width: 2px;
    border-style: solid;
}

:global .ant-tag-default.ant-tag-green {
    background: var(--ff-secondary-green-100);
    border-color: var(--ff-secondary-green-200);
    color: var(--ff-secondary-green-900);
}

:global .ant-tag-selected.ant-tag-green {
    background: white;
    border-color: var(--ff-secondary-green-200);
    color: var(--ff-secondary-green-900);
}

/* Global form styles */
:global .ant-form-vertical .ant-form-item-label{
    padding: 0px 0 2px;
}
:global .ant-form-item {
    margin-bottom: 15px;
}

/* Utils */
:global .text-red{
    color: var(--loop54-red-color);
}
:global .text-green{
    color: var(--loop54-primary-color);
}

:global .text-bold {
    font-weight: bold;
}

/* Ant Modal styles */
:global .ant-modal-mask, :global .ant-image-preview-mask{
    backdrop-filter: blur(5px);
    background-color: rgb(8 8 33 / 45%);
}

/* Ant button styles */
:global .ant-btn{
    letter-spacing: 1px;
    font-size: 15px;
}

:global .ant-btn-round.ant-btn-lg {
    font-size: 15px;
}

:global .ant-btn:hover, :global .ant-btn:focus, :global .ant-btn:active {
    text-decoration: none;
    background: #F1E7FC;
    color: var(--loop54-btn-color);
    border-color: var(--loop54-btn-border-color);
}

:global .ant-btn-primary:hover, :global .ant-btn-primary:focus {
    color: var(--loop54-btn-color);
    background: #fff;
    border-color: var(--loop54-btn-border-color);
}

:global .ant-btn-ghost:hover, :global .ant-btn-ghost:focus{
    background-color: #fff;
}

:global .ant-btn-icon-only {
    border-width: 0px;
}

/* Ant icon styles */
:global .anticon {
    --icon-color: var(--loop54-light-purple-color);

    color: var(--icon-color);
}

:global .ant-input[disabled] {
    color: rgba(0, 0, 0, 0.5);
    background-color: #E9E9E9;
}

/*Drawer styles*/
:global body.drawer-open{
    overflow: hidden;
}

:global .ant-drawer-mask{
    background-color: rgb(8 8 33 / 45%);
    backdrop-filter: blur(3px);
}

/* popover styles */
:global .ant-popover .ant-popover-buttons .ant-btn{
    height: 30px;
    font-size: 12px;
}

/* Fade in content after 150ms, useful for not showing loading and spinners directly */
:global @keyframes loadingFade {
    to {
        opacity: 1;
    }
}
:global .suspense-loading-delay {
    animation: loadingFade 150ms ease-in forwards;
    animation-delay: 1s;
    opacity: 0;
}
