@import "../../Loop54.css";

/* This entire file is a bit of a hack intended to get notifications from react-notification-system to look a bit like the notifications that come
   with Antd. Many of the directives in this file are copied from the Antd styling. */

.notificationSystem :global(.notifications-tr) {
    box-sizing: border-box;
    margin: 0 20px 0 0;
    padding: 0;
    color: rgba(0,0,0,0.65);
    font-size: 14px;
    line-height: 1.5;
    position: fixed;
    z-index: 1010;
    width: 384px;
    max-width: calc(100vw - 32px);
    right: 0px;
    /* Push the notification wrapper 91px from the top and add the regular margin for the notification */
    top: calc(91px + 20px);
    bottom: auto;
}

.notificationSystem :global(.notification) {
    position: relative;
    margin-bottom: 16px;
    padding: 16px 24px;
    overflow: hidden;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 4px 12px rgba(0,0,0,0.15);
    opacity: 0;
    transition: 0.3s ease-in-out;
    transform: translate3d(0,0,0);
    will-change: transform, opacity;
}

.notificationSystem :global(.notification) p:lastchild{
    margin-bottom:0;
}

.notificationSystem :global(.notification-hidden) {
    opacity: 0;
}

.notificationSystem :global(.notification-visible) {
    opacity: 1;
}

.notificationSystem :global(.notification-title) {
    padding: 0 24px 0 0;
    display: inline-block;
    margin: 0 0 4px 48px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
    line-height: 24px;
}

.notificationSystem :global(.notification-dismiss) {
    cursor: pointer;
    font-size: 24px;
    position: absolute;
    line-height: 16px;  /* Aim for a bounding box about the size of the icon */
    top: 10px;
    right: 12px;
    color: rgba(0, 0, 0, 0.45);
    outline: none;
    transition: color .3s ease;
}

.notificationSystem .notificationContent {
    margin: 0 0 0 48px;
    font-size: 14px;
    word-break: break-word;
}

.notificationIcon {
    position: absolute;
    left: 20px;
    font-size: 37px;
    top: 22px;

}

:global(.notification-success) .notificationIcon {
    color: var(--loop54-primary-color);
}

:global(.notification-info) .notificationIcon {
    color: var(--loop54-blue-color);
}

:global(.notification-warning) .notificationIcon {
    color: var(--loop54-yellow-color);
}

:global(.notification-error) .notificationIcon {
    color: var(--loop54-red-color);
}
