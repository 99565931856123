/*Please see HACKING.md for styling of Antd components.*/
@import "Loop54.css";

.menu :global .ant-menu-item-selected::after {
    border-right: 0px;
    display:none;
}

.menu :global .ant-menu-submenu .ant-menu-submenu-title{
    text-transform: uppercase;
}

.menu :global .ant-menu .ant-menu-submenu .ant-menu-submenu-title .anticon{
    font-size: 18px;
    position: relative;
    top:1px;
}

.menu :global .ant-menu-submenu.ant-menu-submenu-selected {
    color: var(--loop54-primary-color);
    position: relative;
}

.menu :global .ant-menu-submenu-title:hover {
    color: var(--loop54-primary-color);
}

.menu :global .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before {
    background: linear-gradient(to right, var(--loop54-primary-color), var(--loop54-primary-color));
}

.menu :global .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after{
    background: linear-gradient(to right, var(--loop54-primary-color), var(--loop54-primary-color));
}

.menu :global .ant-menu-submenu.ant-menu-submenu-selected:after {
    content:" ";
    position: absolute;
    left:0;
    top:0;
    height: 100%;
    width: 4px;
    background-color: var(--loop54-primary-color);
}

.menu :global .ant-menu-submenu .ant-menu-item {
    height: 47px;
    line-height: 47px;
    padding-left: 62px !important;
    position: relative;
}

.menu :global .ant-menu-item, .menu :global .ant-menu-submenu-title {
    text-transform: uppercase;
    letter-spacing: 0.75px;
    font-size: 16px;
}

.menu :global .ant-menu-sub .ant-menu-item-only-child a{
    font-size: 14px;
    letter-spacing: 0.65px;
}

.menu :global .ant-menu-submenu .ant-menu-item.ant-menu-item-selected:before {
    content: " ";
    background-color: var(--loop54-layout-bg);
    width: 82%;
    position: absolute;
    height: 100%;
    z-index:1;
    right:0;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
}

.menu :global .ant-menu-submenu .ant-menu-item.ant-menu-item-selected a {
   position: relative;
   z-index:2;
}

:global .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #fff;
}

.menu :global .anticon{
     /* Figma design specify `2px -3px` but makes smaller icons illegible, half of it is much better */
    filter: drop-shadow(1px -1.5px 0px var(--loop54-cyan-color));
}
