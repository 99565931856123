:root {
    --loop54-primary-color: #000073;
    --loop54-red-color: #FF5900;
    --loop54-blue-color: #1a7caf;
    --loop54-yellow-color: #FFBF00; 
    --loop54-light-purple-color: #AA73F2;
    --loop54-light-cyan-color: #94FFB9;
    --loop54-cyan-color: #4EFF99;
    --loop54-documentation-light-turqoise-color: #b9f0ed;
    --loop54-heading-color: #42525F;
    --loop54-tooltip-color: #000073;
    --loop54-border-color: #efefff;
    --loop54-card-shadow: 0px 0px 6px #efefff;
    --loop54-btn-border-color: #AA73F2;
    --loop54-btn-color: #000073;
    --loop54-layout-bg: #f5f8ff;
    --loop54-axis-color: #e6e6e6;

    --ff-secondary-green-100: #C2FFD4;
    --ff-secondary-green-200: #94FFB9;
    --ff-secondary-green-900: #184545;
    --ff-secondary-red-100: #FFCCB8;
    --ff-secondary-red-200: #FFAB8A;
    --ff-secondary-red-900: #C13600;
    --ff-light-purple-color-100: #DBC5F8;
    --ff-light-purple-color-900: #5600DA;
    --ff-yellow-900: #FF6D00;

    font-family: 'Inter var', sans-serif;
}
